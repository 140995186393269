<template>
  <y_layout>
    <div class="full-bg" :style="fullBgImg"></div>
    <div class="flex-def flex-zCenter flex-cCenter"
         style="height: 100%;">
      <div class="box-card flex-def">
        <el-image v-if="login_banner" style="height: 37.5rem;width: 28rem" :src="login_banner | tomedia"></el-image>
        <div class="form-box flex-def flex-zCenter flex-cCenter">
          <div class="form-box-item flex-def flex-zCenter flex-cCenter flex-zTopBottom">
            <div style="font-size: 2rem;font-weight: 600;margin-bottom: 3rem">
              {{ login_title ? login_title : "后台登陆" }}
            </div>
            <el-form label-position="top" :model="ruleForm" :rules="rules" ref="ruleForm" class="ruleForm">
              <el-form-item label="登陆账号" prop="username">
                <el-input size="normal" v-model="ruleForm.username" placeholder="请输入登陆账号"></el-input>
              </el-form-item>
              <el-form-item label="密码" prop="password">
                <el-input size="normal" type="password" show-password v-model="ruleForm.password" placeholder="请输入密码"></el-input>
              </el-form-item>
            </el-form>
            <div class="flex-def flex-cCenter flex-zBetween" style="width: 100%">
              <el-checkbox v-model="remember" style="font-weight: normal">记住密码</el-checkbox>
              <!--              <el-button type="text" size="mini" @click="$router.push('/forget')" :underline="false" style="font-weight: normal">忘记密码？</el-button>-->
            </div>
            <div style="width: 100%;margin-top: 1rem">
              <el-button size="normal" @click="login" type="primary" style="width: 100%">登陆</el-button>
            </div>
            <div style="width: 100%;margin-top: 1rem">
<!--              <el-button @click="$router.push('/register')" style="width: 100%">注册账号</el-button>-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </y_layout>
</template>

<script>
import y_layout from "@/components/sys/y_layout";

export default {
  components: {y_layout},
  name: "login",
  data() {
    return {
      remember: false,
      ruleForm: {
        username: "",
        password: "",
      },
      rules: {
        username: [
          {required: true, message: '请输入登陆账号', trigger: 'blur'},
        ],
        password: [
          {required: true, message: '请输入登陆密码', trigger: 'blur'},
        ]
      }
    }
  },
  computed: {
    login_banner() {
      return this.$store.state.sys.global.login.banner
    },
    login_title() {
      return this.$store.state.sys.global.login.title
    },
    fullBgImg(){
      let img = this.$store.state.sys.global.login.bg_img;
      if(img === "")return "";
      if (!img.includes("http")) {
        img = this.$store.state.sys.global.attachment_url + img;
      }
      return `background-image:url(${img})`;
    }
  },
  mounted() {

  },
  methods: {
    login() {
      this.$api.sys.account.login(this.ruleForm).then((res) => {
        this.$message.success("欢迎回来～");
        if(res?.info?.uni){
          location.replace(`${location.origin}/?i=${res?.info?.uni?.id}/#/home`);
          return
        }
        // if (this.$route.query.redirect) {
        //   this.$router.push(this.$route.query.redirect);
        //   return;
        // }
        this.$router.push({
          name: res.home_page,
        });
      })
    }
  }
}
</script>

<style>
@import "./sys.css";

.full-bg {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.box-card {
  border-radius: 1rem;
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, .4);
  overflow: hidden;
}

.main-box {
  background-color: unset;
}

.el-footer {
  background-color: unset;
}

.el-footer > div {
  background-color: unset !important;
}
</style>