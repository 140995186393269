<template>
  <el-container>
    <el-main class="main-box">
      <slot></slot>
    </el-main>
    <el-footer class="footer-box" height="3rem">
      <y_layout_foot>
        <div v-if="$store.state.sys.global.icp">
          备案号：
          <a href="http://beian.miit.gov.cn/" target="_blank">{{ $store.state.sys.global.icp }}</a>
        </div>
      </y_layout_foot>
    </el-footer>
  </el-container>
</template>

<script>
import y_layout_foot from "@/components/common/y_layout_foot";

export default {
  name: "y-layout",
  components: {
    y_layout_foot
  },
  data() {
    return {}
  },
  mounted() {

  },
  methods: {}
}
</script>

<style>
.main-box {
  background-color: #f8f8f8;
  height: calc(100vh - 3rem);
  width: 100%
}

.footer-box {
  background-color: #f1f1f1;
  font-size: 12px;
  color: #888888;
}

a, a:focus {
  color: inherit;
  text-decoration: none;
}

.el-footer {
  padding: 0;
}
</style>